import React from 'react'
import { useDropzone } from 'react-dropzone'
import { DocumentDuplicateIcon } from '@heroicons/react/outline'
import { twMerge } from 'tailwind-merge'

import { getCxFromStyles } from '../../helpers'

import styles from './Dropzone.module.scss'

import type { Accept } from 'react-dropzone'

const Dropzone = ({
    acceptFormats = {
        'image/*': ['.jpg', '.jpeg', '.png'],
    },
    disableClick,
    disabled,
    isMultiple,
    hasError,
    className,
    onSelect,
}: React.PropsWithChildren<{
    acceptFormats?: Accept | undefined
    className?: string
    disableClick?: boolean
    hasError?: boolean
    isMultiple: boolean
    disabled?: boolean
    onSelect: (files: File[]) => void
}>) => {
    const cx = getCxFromStyles(styles)

    const { getRootProps, isDragActive, isDragAccept, isDragReject } =
        useDropzone({
            accept: acceptFormats,
            disabled,
            multiple: isMultiple,
            noClick: disableClick,
            maxSize: 10 * 1024 * 1024,
            onDrop: onSelect,
        })

    return (
        <div
            {...getRootProps()}
            className={cx(
                'dropzone',
                {
                    isDragActive: isDragActive,
                    isDragAccept: isDragAccept,
                    isDragReject: isDragReject,
                    error: hasError,
                },
                className
            )}
        >
            <div className="self-center space-y-1 text-center">
                <DocumentDuplicateIcon
                    strokeWidth={1}
                    className={twMerge(
                        'mx-auto w-12 text-gray-800',
                        isDragAccept && 'text-green-600'
                    )}
                />
                <div
                    className={twMerge(
                        'flex text-sm text-gray-600',
                        isDragAccept && 'text-green-600'
                    )}
                >
                    <span
                        className={twMerge(
                            'font-medium text-indigo-600',
                            isDragAccept && 'text-green-700'
                        )}
                    >
                        Prześlij dokument
                    </span>
                    <p className="pl-1">bądź przeciągnij tutaj</p>
                </div>
                <p className="text-xs text-gray-500">XLS, XLSX do 10MB</p>
            </div>
        </div>
    )
}

export default Dropzone
