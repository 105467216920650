import React from 'react'
import { Formik, Form } from 'formik'
import { Link } from 'react-router-dom'

import { Button, Label } from './ui'
import { TextField } from './forms'

import type { FormSubmitFn, LoginForm } from '../types'

export default function Login({
    onSubmit,
}: {
    onSubmit: FormSubmitFn<LoginForm>
}) {
    return (
        <Formik initialValues={{ email: '', password: '' }} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
                <Form className="space-y-6">
                    <div>
                        <Label>Email</Label>
                        <TextField
                            id="email"
                            name="email"
                            autoComplete="email"
                        />
                    </div>

                    <div>
                        <Label>Hasło</Label>
                        <TextField
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                        />
                    </div>

                    <div className="flex items-center">
                        <div className="text-sm">
                            <Link
                                to="/forgot-password"
                                className="text-indigo-600 hover:text-indigo-700"
                            >
                                Zapomniałeś hasło?
                            </Link>
                        </div>
                    </div>

                    <div>
                        <Button
                            variant="primary"
                            className="w-full"
                            type="submit"
                            loading={isSubmitting}
                        >
                            Zaloguj się
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
