import type { ListFilterState, ListFilterAction } from '../types'

export function listReducer(state: ListFilterState, action: ListFilterAction) {
    switch (action.type) {
        case 'setSorting':
            return {
                ...state,
                sort_by: action.payload,
                page: 1,
            }

        case 'setSortingDirection':
            return {
                ...state,
                sort_direction: action.payload,
                page: 1,
            }

        case 'setPage':
            return {
                ...state,
                page: action.payload,
            }

        default:
            return state
    }
}
