import React from 'react'
import { DownloadIcon } from '@heroicons/react/outline'

import { Button } from '.'

export default function ExportButton({
    onClick,
    isExporting,
}: {
    onClick: () => void
    isExporting?: boolean
}) {
    return (
        <Button
            type="button"
            variant="primary"
            onClick={onClick}
            loading={isExporting}
            iconRight={<DownloadIcon className="w-4 h-4" />}
        >
            Pobierz
        </Button>
    )
}
