import { useMutation } from 'react-query'

import client from '../client'

import type { UseMutationResult } from 'react-query'
import type {
    ResetPasswordRequest,
    ResetPasswordResponse,
    ResponseData,
    ResponseError,
} from '../types'

export const resetPassword = (
    data: ResetPasswordRequest
): Promise<ResponseData<ResetPasswordResponse>> =>
    client.post('/reset-password', data)

export const useResetPassword = (): UseMutationResult<
    ResponseData<ResetPasswordResponse>,
    ResponseError,
    ResetPasswordRequest
> => useMutation((data) => resetPassword(data))
