import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/solid'

import { DropdownButton } from '.'

import type { DropdownItem } from '../../types'

interface DropdownProps {
    value?: DropdownItem
    items: DropdownItem[]
    disabled?: boolean
    onChange?: (value?: DropdownItem) => void
}

export default function Dropdown({
    value,
    items,
    disabled,
    onChange,
}: DropdownProps) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Listbox
            value={value}
            onChange={(value?) => !!onChange && onChange(value)}
        >
            {({ open }) => (
                <div className="relative">
                    <DropdownButton
                        as={Listbox.Button}
                        value={value}
                        variant="md"
                        isOpen={isOpen}
                        disabled={disabled}
                        onClick={() => setIsOpen((prevState) => !prevState)}
                    />

                    <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm">
                            {items.map((item) => (
                                <Listbox.Option
                                    key={item.id}
                                    className={({ active }) =>
                                        classNames(
                                            'cursor-pointer select-none relative py-2 pl-3 pr-9',
                                            {
                                                'text-indigo-700': active,
                                                'text-gray-700': !active,
                                            }
                                        )
                                    }
                                    value={item}
                                >
                                    {({ selected, active }) => (
                                        <>
                                            <span
                                                className={classNames(
                                                    'block truncate',
                                                    {
                                                        'font-semibold text-indigo-700':
                                                            selected,
                                                        'font-normal':
                                                            !selected,
                                                    }
                                                )}
                                            >
                                                {item.name}
                                            </span>

                                            {selected ? (
                                                <span
                                                    className={classNames(
                                                        'absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-700'
                                                    )}
                                                >
                                                    <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            )}
        </Listbox>
    )
}
