import React from 'react'
import { Formik, Form } from 'formik'

import { Button, Dropdown, Label } from './ui'
import { TextField } from './forms'
import { USER_ROLES, USER_STATUSES } from '../constants/index'

import type { UpdateUserForm, FormSubmitFn } from '../types'
import type { UserResponse } from '../api/types'

type UserProps = {
    firstName: string
    lastName: string
    email: string
    role: UserResponse['role']
    isActive: boolean
    onSubmit: FormSubmitFn<UpdateUserForm>
}

export default function User({
    firstName,
    lastName,
    email,
    role,
    isActive,
    onSubmit,
}: UserProps) {
    return (
        <Formik<UpdateUserForm>
            initialValues={{
                first_name: firstName,
                last_name: lastName,
                email: email,
                role: USER_ROLES.find((item) => item.id === role.value),
                status: USER_STATUSES.find((item) =>
                    isActive ? item.id === 'active' : item.id === 'inactive'
                ),
            }}
            onSubmit={onSubmit}
        >
            {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                    <div className="mt-8 bg-white">
                        <div className="px-4 py-12">
                            <div className="mx-auto max-w-3xl">
                                <h2 className="mt-8 text-lg leading-6 font-medium">
                                    Informacje o profilu
                                </h2>
                                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="col-span-1">
                                        <Label>Imię</Label>
                                        <TextField name="first_name" />
                                    </div>
                                    <div className="col-span-1">
                                        <Label>Nazwisko</Label>
                                        <TextField name="last_name" />
                                    </div>
                                </div>
                                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="col-span-2">
                                        <Label>Email</Label>
                                        <TextField name="email" />
                                    </div>
                                </div>
                                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="col-span-2">
                                        <Label>Rola</Label>
                                        <Dropdown
                                            value={values.role}
                                            items={USER_ROLES}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="col-span-2">
                                        <Label>Status</Label>
                                        <Dropdown
                                            items={USER_STATUSES}
                                            value={values.status}
                                            onChange={(value) =>
                                                setFieldValue('status', value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex mt-8 pt-5 border-t border-gray-200 items-center justify-between">
                                    <div className="flex-1"></div>
                                    <div className="flex-1 flex items-center justify-end ml-3">
                                        <Button as="link" to="/users">
                                            Anuluj
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="ml-3"
                                            loading={isSubmitting}
                                        >
                                            Zapisz
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
