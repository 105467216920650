import React from 'react'
import { Outlet } from 'react-router-dom'

import { ReactComponent as Logo } from '../assets/logo.svg'

export default function LoginLayout() {
    return (
        <div className="flex flex-col justify-center pb-12 px-4 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="flex justify-center">
                    <Logo />
                </h2>
            </div>
            <div className="mt-8 mx-auto w-full max-w-sm sm:max-w-md">
                <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
