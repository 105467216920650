import React from 'react'
import { SearchIcon } from '@heroicons/react/solid'

import { USER_ROLES, USER_STATUSES } from '../constants/index'
import { Label, Dropdown } from './ui'

import { UserListFilterAction, UserListFilterState } from '../types'

const ALL_ITEM = { id: '', name: 'Wszystko' }

const UserListFilters: React.FC<{
    filters: UserListFilterState
    filterAction: React.Dispatch<UserListFilterAction>
}> = ({ filters, filterAction }) => {
    return (
        <div className="mb-4 px-3 py-4 bg-white rounded-lg">
            <div className="grid grid-cols-4 gap-4">
                <div>
                    <Label>Nazwa lub email</Label>
                    <div className="shadow-sm rounded-md">
                        <div className="relative">
                            <input
                                type="text"
                                name="name"
                                className="block w-full pr-10 appearance-none px-3 py-2 focus:outline-none text-sm rounded-md border border-gray-300 placeholder-gray-400 focus:ring-1 focus:ring-indigo-600 focus:border-indigo-600"
                                autoComplete="off"
                                placeholder=""
                                value={filters.query}
                                onChange={(e) =>
                                    filterAction({
                                        type: 'setFilterQuery',
                                        payload: e.target.value,
                                    })
                                }
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <SearchIcon
                                    className="h-5 w-5 text-gray-500"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Label>Rola</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(USER_ROLES)}
                            value={
                                USER_ROLES.find(
                                    (item) => item.id === filters.role?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterRole',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>Status</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(USER_STATUSES)}
                            value={
                                USER_STATUSES.find(
                                    (item) => item.id === filters.status?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterStatus',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserListFilters
