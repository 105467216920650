import { createContext } from 'react'

export interface AuthContext {
    setToken: (token: string, expires_at: string) => void
    clearToken: () => void
    getToken: () => string | null
    isSignedIn: boolean
    isLoading: boolean
}

export default createContext<AuthContext>({
    setToken: (_token: string, _expires_at: string) => Promise.resolve(),
    clearToken: () => {},
    getToken: () => null,
    isSignedIn: false,
    isLoading: true,
})
