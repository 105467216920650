import client from '../client'
import { useMutation, UseMutationOptions, useQuery } from 'react-query'

import type { UseQueryOptions, UseQueryResult } from 'react-query'
import type {
    AssignmentInfoResponse,
    UpdateAssigmentFileVariable,
    ResponseData,
    ResponseError,
} from '../types'

export const getTemplateFile = () => {
    return client.get('/assignments/excel/download', {
        headers: {
            Accept: 'application/vnd.ms-excel',
        },
        responseType: 'blob',
    })
}

export const getAssignmentInfo = (): Promise<
    ResponseData<AssignmentInfoResponse>
> => client.get('/assignments/info')

export const useAssignmentInfo = (
    queryOptions?:
        | UseQueryOptions<
              ResponseData<AssignmentInfoResponse>,
              ResponseError,
              ResponseData<AssignmentInfoResponse>,
              string[]
          >
        | undefined
): UseQueryResult<ResponseData<AssignmentInfoResponse>, ResponseError> =>
    useQuery(['assignments-info'], () => getAssignmentInfo(), queryOptions)

const uploadFile = (file: File): Promise<ResponseData<unknown>> => {
    const formData = new FormData()
    formData.append('file', file)
    return client.post('/assignments/excel/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const useAssignmentUploadFile = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<unknown>,
            ResponseError,
            UpdateAssigmentFileVariable
        >,
        'mutationFn'
    >
) => useMutation((data) => uploadFile(data.file), options)
