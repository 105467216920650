import React from 'react'
import { Formik, Form } from 'formik'
import { parseISO, format } from 'date-fns'

import { Button, ExportButton } from './ui'
import FileSelector from './FileSelector'

import type { FormSubmitFn } from '../types'
import type { AssignmentInfoResponse } from '../api/types'

type AssignmentProps = {
    data: AssignmentInfoResponse | null
    fileDownloading: boolean
    onSubmit: FormSubmitFn<any>
    onFileDownload: (filename?: string) => void
}

type ExcelErrorsObject = {
    [key: number]: string
}

export default function Assignment({
    data,
    onSubmit,
    fileDownloading,
    onFileDownload,
}: AssignmentProps) {
    return (
        <Formik initialValues={{ file: undefined }} onSubmit={onSubmit}>
            {({ values, errors, setFieldValue, isSubmitting }) => (
                <Form>
                    <div className="mt-8 bg-white">
                        <div className="px-4 py-12">
                            <div className="mx-auto max-w-3xl">
                                <h2 className="mt-8 text-lg leading-6 font-medium">
                                    Wgraj nowy pakiet zadań Oleofarm
                                </h2>
                                {!data && (
                                    <div className="mt-6 flex flex-row px-6 py-4 bg-indigo-50 rounded items-center text-sm">
                                        <div className="flex-auto">
                                            <p className="font-medium">
                                                Przed przygotowaniem zadań
                                                pobierz szablon pliku:
                                            </p>
                                            <p className="text-gray-500">
                                                Zadania_Oleofarm-szablon.xls
                                            </p>
                                        </div>
                                        <div>
                                            <ExportButton
                                                onClick={onFileDownload}
                                                isExporting={fileDownloading}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="mt-6">
                                    <FileSelector
                                        file={values.file}
                                        hasError={
                                            'file' in errors ||
                                            isValidExcelErrorsObject(errors)
                                        }
                                        onSelect={(file) =>
                                            setFieldValue('file', file)
                                        }
                                        onRemove={() => {
                                            setFieldValue('file', undefined)
                                        }}
                                    />
                                    {isValidExcelErrorsObject(errors) && (
                                        <ul className="mt-2 space-y-0.5">
                                            {Object.values(errors).map(
                                                (error, index) => (
                                                    <li
                                                        key={index}
                                                        className="text-sm text-red-500"
                                                    >
                                                        {error}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    )}
                                </div>
                                <div className="flex mt-8 pt-5 border-t border-gray-200 items-center justify-between">
                                    <div className="flex-1"></div>
                                    <div className="flex-1 flex items-center justify-end ml-3">
                                        <Button as="link" to="/users">
                                            Anuluj
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="ml-3"
                                            loading={isSubmitting}
                                        >
                                            Zapisz
                                        </Button>
                                    </div>
                                </div>
                                {!!data && (
                                    <>
                                        <div className="mt-12">
                                            <h2 className="text-lg leading-6 font-medium">
                                                Aktualny plik
                                            </h2>
                                        </div>
                                        <div className="mt-8 flex flex-wrap flex-row px-6 items-center text-sm leading-5">
                                            <div className="w-2/3">Nazwa</div>
                                            <div className="w-1/3 text-gray-500">
                                                Data dodania
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="flex flex-wrap flex-row px-6 py-4 bg-indigo-50 rounded items-center text-sm">
                                                <div className="w-2/3">
                                                    <span className="text-gray-800">
                                                        {data.filename}
                                                    </span>
                                                </div>
                                                <div className="flex flex-auto flex-row items-center justify-between space-x-2">
                                                    <span className="text-gray-500">
                                                        {format(
                                                            parseISO(
                                                                data.updated_at
                                                            ),
                                                            'dd.MM.yyyy HH:mm'
                                                        )}
                                                    </span>
                                                    <ExportButton
                                                        onClick={() =>
                                                            onFileDownload(
                                                                data.filename
                                                            )
                                                        }
                                                        isExporting={
                                                            fileDownloading
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

function isValidExcelErrorsObject(errors: any): errors is ExcelErrorsObject {
    if (
        typeof errors !== 'object' ||
        errors === null ||
        Array.isArray(errors)
    ) {
        return false
    }

    const keys = Object.keys(errors)
    if (keys.length === 0) {
        return false
    }

    return keys.every(
        (key) => !isNaN(Number(key)) && typeof errors[key] === 'string'
    )
}
