import React from 'react'
import { useFormikContext } from 'formik'

import styles from './ErrorMessage.module.scss'

const ErrorMessage: React.FC<{ name: string }> = ({ name }) => {
    const { errors } = useFormikContext() as { errors: Record<string, string> }

    if (!errors || !(name in errors)) {
        return null
    }

    return <div className={styles.root}>{errors[name]}</div>
}

export default ErrorMessage
