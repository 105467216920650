import React, { useState, useMemo, useReducer } from 'react'

import { DEFAULT_LIST_PER_PAGE } from '../constants/index'
import { useUsersQuery } from '../api'
import { listReducer } from '../helpers'
import { FilterButton } from '../components/ui'
import PageLayout from '../components/PageLayout'
import UserList from '../components/UserList'
import UserListFilters from '../components/UserListFilters'

import type {
    UserListFilterState,
    UserListFilterAction,
    ListFilterAction,
} from '../types'

const filterReducer = (
    state: UserListFilterState,
    action: UserListFilterAction
) => {
    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            query: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterRole') {
        return {
            ...state,
            role: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterStatus') {
        return {
            ...state,
            status: action.payload,
            page: 1,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            query: '',
            role: undefined,
            status: undefined,
            page: 1,
        }
    }

    return listReducer(state, action as ListFilterAction) as UserListFilterState
}

export default function UserListContainer() {
    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        query: '',
        role: undefined,
        status: undefined,
        sort_by: 'id',
        sort_direction: 'desc',
        length: DEFAULT_LIST_PER_PAGE,
        page: 1,
    })

    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true)

    const filterCount = useMemo(
        () =>
            (filters.query.length ? 1 : 0) +
            (filters.role ? 1 : 0) +
            (filters.status ? 1 : 0),
        [filters]
    )

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            query: filters.query || undefined,
            status: undefined,
            role: filters.role ? filters.role.id : undefined,
            is_active: filters.status
                ? filters.status.id === 'active'
                    ? 1
                    : 0
                : undefined,
        }
    }, [filters])

    const usersQuery = useUsersQuery(apiFilters)

    return (
        <PageLayout
            title="Użytkownicy"
            actions={
                <>
                    <span className="ml-2">
                        <FilterButton
                            count={filterCount}
                            filtersExpanded={filtersExpanded}
                            onClick={() =>
                                setFiltersExpanded((prevState) => !prevState)
                            }
                            handleReset={() =>
                                dispatchFilterAction({
                                    type: 'resetFilters',
                                })
                            }
                        />
                    </span>
                </>
            }
        >
            <>
                {filtersExpanded && (
                    <UserListFilters
                        filters={filters}
                        filterAction={dispatchFilterAction}
                    />
                )}
                <UserList
                    usersQuery={usersQuery}
                    filters={filters}
                    filterCount={filterCount}
                    filterAction={dispatchFilterAction}
                />
            </>
        </PageLayout>
    )
}
