import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { QUERY_CACHE_USERS_KEY } from '../constants/index'
import { useUpdateUser, useUserByIdQuery } from '../api'
import { useFlashMessage } from '../hooks'
import { Loader } from '../components/ui'
import PageLayout from '../components/PageLayout'
import User from '../components/User'

import type { UpdateUserForm, FormSubmitFn } from '../types'
import { useQueryClient } from 'react-query'

export default function UserContainer() {
    const { id } = useParams()
    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const navigate = useNavigate()
    const userQuery = useUserByIdQuery(id!, {
        enabled: !!id,
    })
    const updateUserQuery = useUpdateUser({
        onSuccess: async () => {},
    })

    const handleSubmit: FormSubmitFn<UpdateUserForm> = (
        values,
        formikHelpers
    ) => {
        if (!id) {
            return
        }

        updateUserQuery.mutate(
            {
                id,
                data: {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    is_active: values.status?.id === 'active',
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: 'Dane użytkownika zostały zaktualizowane.',
                    })
                    formikHelpers.setSubmitting(false)
                    navigate('/users')
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_USERS_KEY,
                        'index',
                    ])
                    queryClient.removeQueries([QUERY_CACHE_USERS_KEY, id])
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    if (userQuery.isLoading) {
        return <Loader />
    }

    if (userQuery.isError) {
        return <div className="text-red-600">{userQuery.error.message}</div>
    }

    if (!userQuery.isSuccess) {
        return null
    }

    return (
        <PageLayout
            title={`Edytuj profil: ${userQuery.data.data.first_name} ${userQuery.data.data.last_name}`}
        >
            <User
                firstName={userQuery.data.data.first_name}
                lastName={userQuery.data.data.last_name}
                email={userQuery.data.data.email}
                role={userQuery.data.data.role}
                isActive={userQuery.data.data.is_active}
                onSubmit={handleSubmit}
            />
        </PageLayout>
    )
}
