import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { useAdminLogin } from '../api'
import { useAuth } from '../hooks'
import Login from '../components/Login'

import type { LoginForm, FormSubmitFn } from '../types'

export default function LoginContainer() {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const auth = useAuth()
    const { mutate: login } = useAdminLogin()

    const authToken = auth.getToken()

    if (authToken) {
        return <Navigate to="/" />
    }

    const onSubmit: FormSubmitFn<LoginForm> = (values, formikHelpers) => {
        login(values, {
            onSuccess: ({ data }) => {
                auth.setToken(data.token, data.expires_at)
                queryClient.removeQueries(['user'])
                navigate('/', { replace: true })
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
            },
        })
    }

    return <Login onSubmit={onSubmit} />
}
