import React from 'react'
import classNames from 'classnames'

export interface PageLayoutProps {
    className?: string
    renderTitle?: React.ReactElement
    title?: string
    children?: React.ReactElement
    actions?: React.ReactElement
}

export const PageLayout: React.FC<PageLayoutProps> = ({
    className,
    title,
    renderTitle,
    children,
    actions,
}) => {
    return (
        <div
            className={classNames(
                'flex flex-col flex-1 py-3 xl:py-6 print:p-0',
                className
            )}
        >
            <div className="px-2 mb-3 md:mb-4 md:px-4 xl:px-8 print:p-0 print:mb-8">
                <div className="flex items-center justify-between">
                    <div className="flex-1">
                        <h2 className="text-2xl leading-normal whitespace-nowrap print:text-3xl">
                            {!!renderTitle && renderTitle}
                            {!renderTitle && (
                                <span className="font-bold">{title}</span>
                            )}
                        </h2>
                    </div>
                    {!!actions && (
                        <div className="flex-1">
                            <div className="flex items-center justify-end md:mt-0 md:ml-4 print:hidden">
                                {actions}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex flex-col flex-1 px-2 md:px-4 xl:px-8 print:p-0">
                {children}
            </div>
        </div>
    )
}

export default PageLayout
