import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import client from '../client'

import type { ResponseError, LoginResponse, ResponseData } from '../types'

export const getUser = (): Promise<ResponseData<LoginResponse>> =>
    client.get('/user')

export const useGetUser = (
    queryOptions?:
        | UseQueryOptions<
              ResponseData<LoginResponse>,
              ResponseError,
              ResponseData<LoginResponse>,
              string[]
          >
        | undefined
): UseQueryResult<ResponseData<LoginResponse>, ResponseError> =>
    useQuery(['user'], () => getUser(), queryOptions)
