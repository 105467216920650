import { useQuery, useMutation } from 'react-query'
import qs from 'qs'

import client from '../client'
import { QUERY_CACHE_USERS_KEY } from '../../constants/index'

import type {
    UseQueryOptions,
    UseQueryResult,
    UseMutationOptions,
} from 'react-query'
import type {
    ResponseData,
    ResponseList,
    ResponseError,
    UserResponse,
    UserListResponse,
    UpdateUserVariables,
} from '../types'

export const getUsers = <T>(filters?: {}): Promise<T> =>
    client.get('/users' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useUsersQuery = <T = ResponseList<UserListResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_USERS_KEY, 'index', filters],
        () => getUsers<T>(filters),
        options
    )

export const getUserById = (id: string): Promise<ResponseData<UserResponse>> =>
    client.get(`/users/${id}`)

export const useUserByIdQuery = <TData = ResponseData<UserResponse>>(
    id: string,
    queryOptions?: UseQueryOptions<
        ResponseData<UserResponse>,
        ResponseError,
        TData,
        [string, string]
    >
): UseQueryResult<TData, ResponseError> =>
    useQuery([QUERY_CACHE_USERS_KEY, id], () => getUserById(id), queryOptions)

const updateUser = ({
    id,
    data,
}: UpdateUserVariables): Promise<ResponseData<UserResponse>> =>
    client.patch(`/users/${id}`, data)

export const useUpdateUser = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<UserResponse>,
            ResponseError,
            UpdateUserVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => updateUser(data), options)
