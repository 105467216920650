import React from 'react'
import classNames from 'classnames'
import { PencilIcon } from '@heroicons/react/solid'

import { USER_ROLES } from '../constants/index'
import { Button, Spinner } from './ui'
import TableFooter from './TableFooter'

import type { UseQueryResult } from 'react-query'
import type { UserListFilterState, UserListFilterAction } from '../types'
import type { ResponseList, UserListResponse } from '../api/types'

type UserListProps = {
    usersQuery: UseQueryResult<ResponseList<UserListResponse>>
    filters: UserListFilterState
    filterCount: number
    filterAction: React.Dispatch<UserListFilterAction>
}

type TableProps = {
    data: UserListResponse
}

const UserList: React.FC<UserListProps> = ({
    usersQuery,
    filters,
    filterCount,
    filterAction,
}) => {
    return (
        <div className="my-8 bg-white p-2 rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 text-xs text-gray-500 uppercase">
                    <tr>
                        <th
                            scope="col"
                            className="w-[90px] px-6 py-3 text-left font-medium tracking-wider"
                        >
                            Id
                        </th>
                        <th
                            scope="col"
                            className="w-auto px-6 py-3 text-left font-medium tracking-wider"
                        >
                            Nazwa
                        </th>
                        <th
                            scope="col"
                            className="w-auto px-6 py-3 text-left font-medium tracking-wider"
                        >
                            Email
                        </th>
                        <th
                            scope="col"
                            className="w-1 min-w-[80px] px-6 py-3 text-left font-medium tracking-wider box-content"
                        >
                            Rola
                        </th>
                        <th
                            scope="col"
                            className="w-1 min-w-[80px] px-6 py-3 text-left font-medium tracking-wider box-content"
                        >
                            Status
                        </th>
                        <th
                            scope="col"
                            className="w-1 px-6 py-3 text-left font-medium tracking-wider"
                        >
                            Link aktywacyjny
                        </th>
                        <th
                            scope="col"
                            className="w-1 min-w-[80px] px-6 py-3 text-left font-medium tracking-wider box-content"
                        >
                            Opcje
                        </th>
                    </tr>
                </thead>
                {usersQuery.isSuccess && usersQuery.isFetched && (
                    <>
                        {usersQuery.data.meta.total > 0 && (
                            <TableBody data={usersQuery.data.data} />
                        )}
                    </>
                )}
            </table>
            {usersQuery.data?.meta.total === 0 && (
                <div className="divide-y divide-gray-200 border-t border-gray-200 text-md text-gray-500 leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (_, index) => (
                            <div
                                key={index}
                                className={classNames('relative h-[68px]', {
                                    'bg-gray-50': index % 2,
                                })}
                            >
                                {index === 3 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        {filterCount === 0 && (
                                            <span>
                                                Nie ma jeszcze żadnych
                                                użytkowników.
                                            </span>
                                        )}
                                        {filterCount > 0 && (
                                            <span>
                                                Nie znaleziono wyników
                                                wyszukiwania.
                                            </span>
                                        )}
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            {usersQuery.isLoading && (
                <div className="divide-y divide-gray-200 border-t border-gray-200 text-sm leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (_, index) => (
                            <div
                                key={index}
                                className={classNames('relative h-[68px]', {
                                    'bg-gray-50': index % 2,
                                })}
                            >
                                {index === 4 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        <Spinner className="p-0" />
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            <TableFooter
                meta={usersQuery.data?.meta}
                filterAction={filterAction}
            />
        </div>
    )
}

const TableBody: React.FC<TableProps> = ({ data }) => {
    return (
        <tbody className="text-gray-500 text-sm bg-white divide-y divide-gray-200 leading-5">
            {data.map((item, index) => (
                <tr
                    key={item.id}
                    className={classNames({
                        'bg-gray-50': index % 2,
                    })}
                >
                    <td className="px-6 py-6">
                        <span>{item.id}</span>
                    </td>
                    <td className="px-6 py-6">
                        <span className="text-gray-900 font-medium">
                            {item.first_name} {item.last_name}
                        </span>
                    </td>
                    <td className="px-6 py-6">{item.email}</td>
                    <td className="px-6 py-6">
                        <span
                            className={classNames(
                                'inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium',
                                {
                                    'bg-gray-100 text-gray-800':
                                        index % 2 === 0,
                                    'bg-white text-gray-600': index % 2,
                                }
                            )}
                        >
                            {USER_ROLES.find(
                                (role) => role.id === item.role.value
                            )?.short_name || '-'}
                        </span>
                    </td>
                    <td className="px-6 py-6">
                        <span
                            className={classNames(
                                'inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium',
                                {
                                    'text-green-800 bg-green-100':
                                        item.is_active,
                                    'text-red-800 bg-red-100': !item.is_active,
                                }
                            )}
                        >
                            {item.is_active ? 'Aktywny' : 'Nieaktywny'}
                        </span>
                    </td>
                    <td className="px-6 py-3">
                        <Button
                            variant="default"
                            size="sm"
                            className={classNames({
                                'pointer-events-none': false,
                            })}
                            disabled={true}
                            loading={false}
                            iconRight={undefined}
                            onClick={() => {}}
                        >
                            <span className="font-medium">Wyślij link</span>
                        </Button>
                    </td>
                    <td className="px-6 py-1 text-right">
                        <span className="font-normal">
                            <Button
                                as="link"
                                to={`/users/${item.id}`}
                                variant="secondary"
                                size="sm"
                                iconRight={
                                    <PencilIcon className="text-indigo-500" />
                                }
                            >
                                <span className="font-medium">Edytuj</span>
                            </Button>
                        </span>
                    </td>
                </tr>
            ))}
        </tbody>
    )
}

export default UserList
