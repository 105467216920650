import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Spinner } from '../components/ui'
import { useAuth } from '../hooks'

export default function LogoutContainer() {
    const navigate = useNavigate()
    const auth = useAuth()

    useEffect(() => {
        auth.clearToken()
        navigate('/login', { replace: true })
    }, [navigate, auth])

    return (
        <div className="w-full h-full flex justify-center items-center">
            <Spinner />
        </div>
    )
}
