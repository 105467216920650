import React, { createContext } from 'react'

import type { FlashMessageType } from '../components/FlashMessage'

export interface FlashMessage {
    content: React.ReactNode
    type: FlashMessageType
}

export interface FlashMessageContext {
    message: FlashMessage | null
    show: (flashMessage: FlashMessage, timeout?: number) => void
    dismiss: () => void
}

export default createContext<FlashMessageContext>({
    message: null,
    show: (_flashMessage: FlashMessage, _timeout?: number) => {},
    dismiss: () => {},
})
