import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'

import { getCxFromStyles } from '../helpers'
import { SIDEBAR_ITEMS } from './SidebarItems'

import styles from './Sidebar.module.scss'

import type { Item, SubItem } from './SidebarItems'

interface SidebarProps {
    isSidebarOpen: boolean
    setSidebarOpen: (state: boolean) => void
}

const Sidebar = ({ isSidebarOpen, setSidebarOpen }: SidebarProps) => {
    let location = useLocation()
    const navigate = useNavigate()
    const cx = getCxFromStyles(styles)
    const alwaysOpen = true

    const [currentItem, setCurrentItem] = useState<number | null>(null)

    const isItemActive = useCallback(
        (item: SubItem) =>
            item.matches
                ? item.exact === true
                    ? item.matches.filter(
                          (match: string) => match === location.pathname
                      ).length > 0
                    : item.matches.filter((match: string) =>
                          location.pathname.startsWith(match)
                      ).length > 0
                : item.exact === true
                ? item.to === location.pathname
                : item.exclude
                ? location.pathname.startsWith(item.to) &&
                  !item.exclude.includes(location.pathname)
                : location.pathname.startsWith(item.to),
        [location]
    )

    useEffect(() => {
        setCurrentItem(null)
    }, [isSidebarOpen])

    return (
        <div
            id="main-layout-sidebar"
            className={cx('root', 'print:hidden', {
                isExpanded: isSidebarOpen,
            })}
        >
            <header>
                {isSidebarOpen ? (
                    <div className={cx('header')}>
                        <div className={cx('logo')}>
                            <span>Oleofarm</span>
                        </div>
                    </div>
                ) : (
                    <button
                        className={cx('menuExpander')}
                        onClick={() => setSidebarOpen(true)}
                    >
                        <div className={cx('logo')}>
                            <span>O</span>
                        </div>
                    </button>
                )}
            </header>

            <nav className={cx('nav')}>
                {SIDEBAR_ITEMS.map((item, index) => {
                    const active = item.children.length
                        ? item.children.filter(isItemActive).length > 0
                        : isItemActive(item)

                    return (
                        <Disclosure
                            as="div"
                            key={item.name}
                            defaultOpen={alwaysOpen || currentItem === index}
                            className={cx('item-container', 'space-y-1')}
                        >
                            {({ open }) =>
                                item.children.length === 0 ? (
                                    <Link
                                        to={item.to}
                                        className={cx('navItem', {
                                            disabled: item.disabled,
                                        })}
                                    >
                                        {renderMenuItem({
                                            item,
                                            active,
                                        })}
                                    </Link>
                                ) : (
                                    <>
                                        <div
                                            className={cx('navItem', {
                                                disabled: item.disabled,
                                            })}
                                            onClick={() => {
                                                if (item.to !== '#') {
                                                    navigate(item.to)
                                                }

                                                if (isSidebarOpen) {
                                                    setCurrentItem(
                                                        (prevState) =>
                                                            prevState === index
                                                                ? null
                                                                : index
                                                    )
                                                }
                                            }}
                                        >
                                            {renderMenuItem({
                                                item,
                                                active,
                                            })}
                                            {!alwaysOpen && (
                                                <span
                                                    className={classNames(
                                                        'm-2 h-3 w-3 text-white'
                                                    )}
                                                >
                                                    {currentItem === index && (
                                                        <ChevronDownIcon />
                                                    )}
                                                    {currentItem !== index && (
                                                        <ChevronUpIcon />
                                                    )}
                                                </span>
                                            )}
                                        </div>
                                        <Disclosure.Panel
                                            static
                                            className={cx('navItemPanel', {
                                                closed:
                                                    !alwaysOpen &&
                                                    currentItem !== index,
                                            })}
                                        >
                                            {item.children.map(
                                                ({ ...child }) => {
                                                    return (
                                                        <div key={child.name}>
                                                            {renderChild({
                                                                index,
                                                                child,
                                                            })}
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </Disclosure.Panel>
                                    </>
                                )
                            }
                        </Disclosure>
                    )
                })}
            </nav>
        </div>
    )

    function renderMenuItem({ item, active }: { item: Item; active: boolean }) {
        return (
            <>
                <span
                    className={cx('icon-container', {
                        active,
                    })}
                >
                    <item.icon className={cx('icon')} />
                </span>
                <span className={cx('title')}>{item.name}</span>
            </>
        )
    }

    function renderChild({
        index,
        child,
    }: {
        index: number
        child: Omit<SubItem, 'Gate'>
    }) {
        return (
            <Link
                to={child.to}
                className={cx('navItem', 'subItem', {
                    active: isItemActive(child),
                })}
                onClick={() => {
                    setCurrentItem(index)
                }}
            >
                <span className={cx('title')}>{child.name}</span>
            </Link>
        )
    }
}

export default Sidebar
