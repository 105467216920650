import { useMutation } from 'react-query'

import client from '../client'

import type { UseMutationResult } from 'react-query'
import type {
    RemindPasswordRequest,
    RemindPasswordResponse,
    ResponseData,
    ResponseError,
} from '../types'

export const remindPassword = (
    data: RemindPasswordRequest
): Promise<ResponseData<RemindPasswordResponse>> =>
    client.post('/remind-password', data)

export const useRemindPassword = (): UseMutationResult<
    ResponseData<RemindPasswordResponse>,
    ResponseError,
    RemindPasswordRequest
> => useMutation((data) => remindPassword(data))
