import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { useGetUser } from './api'
import { Loader } from './components/ui'
import { useAuth } from './hooks'

export default function AuthRoutes() {
    const auth = useAuth()
    const user = useGetUser({ enabled: auth.isSignedIn })

    const authToken = auth.getToken()

    if (!authToken) {
        return <Navigate to="/login" />
    }

    if (user.isLoading) {
        return <Loader />
    }

    if (user.isError) {
        return <div>{user.error.message}</div>
    }

    if (user.isSuccess) {
        return <Outlet />
    }

    return null
}
