import React from 'react'
import { DocumentTextIcon, TrashIcon } from '@heroicons/react/outline'

import { getCxFromStyles } from '../helpers'
import { Dropzone } from './ui'

import styles from './FileSelector.module.scss'

const cx = getCxFromStyles(styles)

const FileSelector = (props: {
    file?: File
    className?: string
    hasError?: boolean
    onSelect: (file: File) => void
    onRemove: () => void
}) => {
    const { file, hasError, onSelect, onRemove, className } = props

    return (
        <div className={cx('root', className)}>
            {!file && (
                <Dropzone
                    onSelect={(files) => onSelect(files[0])}
                    isMultiple={false}
                    hasError={hasError}
                    acceptFormats={{
                        'text/csv': ['.csv'],
                        'application/vnd.ms-excel': ['.xls', '.xlsx'],
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                            ['.xls', '.xlsx'],
                        'application/vnd.google-apps.spreadsheet': [
                            '.xls',
                            '.xlsx',
                        ],
                    }}
                />
            )}
            {!!file && (
                <div
                    className={cx('fileSelector', {
                        selected: !hasError,
                        error: hasError,
                    })}
                >
                    <div className="self-center space-y-1 text-center">
                        <DocumentTextIcon className="mx-auto w-12 text-blue-500" />
                        <p className="text-md text-blue-600">
                            <span className="align-middle break-all">
                                {file.name}{' '}
                            </span>
                            <TrashIcon
                                className="pl-1 inline-flex w-7 text-sm text-gray-500 align-middle hover:text-gray-700 cursor-pointer"
                                onClick={onRemove}
                            />
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default FileSelector

/* eslint-enable */
