import React from 'react'
import { ClipboardCheckIcon, UsersIcon } from '@heroicons/react/outline'

export type Item = {
    name: string
    to: string
    icon: React.FC<any>
    exact?: boolean
    disabled?: true
    children: SubItem[]
}

export type SubItem = {
    name: string
    to: string
    matches?: string[]
    exclude?: string[]
    exact?: boolean
}

export const SIDEBAR_ITEMS: Item[] = [
    {
        name: 'Użytkownicy',
        to: '/users',
        exact: false,
        icon: UsersIcon,
        children: [],
    },
    {
        name: 'Zadania Oleofarm',
        to: '/assignments',
        exact: false,
        icon: ClipboardCheckIcon,
        children: [],
    },
]
