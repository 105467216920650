export const USER_ROLES = [
    {
        id: 'representative',
        name: 'Przedstawiciel farmaceutyczny',
        short_name: 'PF',
    },
    {
        id: 'admin',
        name: 'Administrator',
        short_name: 'Admin',
    },
]

export const USER_STATUSES = [
    { id: 'active', name: 'Aktywny' },
    { id: 'inactive', name: 'Nieaktywny' },
]
