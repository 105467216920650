import React from 'react'

import { Spinner } from '.'

const Loader: React.FC<{
    size?: 'sm' | 'md'
    theme?: 'darker' | 'lighter'
}> = ({ size = 'md', theme = 'lighter' }) => {
    return (
        <div className="h-full flex justify-center items-center">
            <Spinner
                size={size}
                variant="default"
                lighter={theme === 'lighter'}
                darker={theme === 'darker'}
            />
        </div>
    )
}

export default Loader
