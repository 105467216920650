import React from 'react'
import { useField } from 'formik'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

import { InputText } from '../ui'
import ErrorMessage from './ErrorMessage'

export default function TextField({
    name,
    trailingAddOn,
    showErrorMessage = true,
    onChange,
    ...props
}: {
    name: string
    trailingAddOn?: string
    showErrorMessage?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>) {
    const [field, meta] = useField(name)

    return (
        <>
            <div className="relative">
                <InputText
                    {...props}
                    name={field.name}
                    value={field.value}
                    handleChange={(e) => {
                        if (!e.target.validity.valid) {
                            return false
                        }

                        field.onChange(e)
                        typeof onChange === 'function' && onChange(e)
                    }}
                    autoComplete="off"
                    trailingAddOn={trailingAddOn}
                    hasError={showErrorMessage && !!meta.error}
                />
                {showErrorMessage && !!meta.error && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                        />
                    </div>
                )}
            </div>
            {showErrorMessage && <ErrorMessage name={field.name} />}
        </>
    )
}
