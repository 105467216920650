import { getTemplateFile } from '../api'

export const useDownloadTemplate = function () {
    return async ({ fileName }: { fileName: string }) => {
        let blob: Blob

        try {
            const { data } = await getTemplateFile()
            blob = data as Blob
        } catch (e) {
            throw e
        }

        const a = document.createElement('a')
        document.body.appendChild(a)
        a.style.display = 'none'
        a.href = URL.createObjectURL(blob)
        a.download = fileName
        a.click()
        a.remove()
    }
}
