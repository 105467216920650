import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import ReactDOM from 'react-dom/client'

import Routes from './Routes'
import FlashMessageProvider from './contexts/FlashMessageProvider'
import AuthProvider from './contexts/AuthProvider'
import reportWebVitals from './reportWebVitals'

import './index.css'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 120_000,
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <FlashMessageProvider>
                    <AuthProvider>
                        <Routes />
                    </AuthProvider>
                </FlashMessageProvider>
            </BrowserRouter>
        </QueryClientProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
