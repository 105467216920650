import React from 'react'
import { Routes, Route } from 'react-router-dom'

import AuthRoutes from './AuthRoutes'
import ScrollToTop from './components/ScrollToTop'
import MainLayout from './components/MainLayout'
import LoginLayout from './components/LoginLayout'
import Login from './containers/Login'
import Main from './containers/Main'
import ForgotPassword from './containers/ForgotPassword'
import SetPassword from './containers/SetPassword'
import ResetPassword from './containers/ResetPassword'
import Logout from './containers/Logout'
import UserList from './containers/UserList'
import User from './containers/User'
import Assignments from './containers/Assignments'

export default function MainRoutes() {
    return (
        <ScrollToTop>
            <Routes>
                <Route element={<LoginLayout />}>
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                    />
                    <Route path="/set-password" element={<SetPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                </Route>
                <Route element={<AuthRoutes />}>
                    <Route element={<MainLayout />}>
                        <Route path="/" element={<Main />} />
                        <Route path="/users">
                            <Route index element={<UserList />} />
                            <Route path=":id" element={<User />} />
                        </Route>
                        <Route path="/assignments" element={<Assignments />} />
                    </Route>
                    <Route path="/logout" element={<Logout />} />
                </Route>
                <Route path="*" element={<div>404</div>} />
            </Routes>
        </ScrollToTop>
    )
}
