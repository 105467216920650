import axios from 'axios'

import { refreshToken } from '../api'

import type { DateISO8601, ResponseError } from './types'

const {
    REACT_APP_API_URL,
    REACT_APP_API_KEY,
    REACT_APP_TOKEN_REFRESH_THRESHOLD_SECONDS,
} = process.env

export interface ApplyInterceptorsProps {
    getToken: () => string | null
    setToken: (token: string, expires_at: DateISO8601) => void
    clearToken: () => void
    getTokenExpirationDate: () => string | null
}

if (typeof REACT_APP_API_URL === 'undefined') {
    throw new Error('API_URL not found in .env file.')
}

if (typeof REACT_APP_API_KEY === 'undefined') {
    throw new Error('API_KEY not found in .env file.')
}

if (typeof REACT_APP_TOKEN_REFRESH_THRESHOLD_SECONDS === 'undefined') {
    throw new Error('TOKEN_REFRESH_THRESHOLD_SECONDS not found in .env file.')
}

const axiosInstance = axios.create()

axiosInstance.defaults.baseURL = REACT_APP_API_URL
axiosInstance.defaults.headers.common.Accept = 'application/json'
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json'

let isRefreshing = false

axiosInstance.interceptors.response.use(
    (response) => {
        if (response.headers['content-type'] !== 'application/json') {
            return Promise.resolve(response)
        }

        return response.data
    },
    async (error) => {
        const normalizedError: ResponseError = {
            message: 'Something went wrong',
            errors: {},
        }

        if (error.response) {
            const { data, status } = error.response

            if (data && data.message) {
                normalizedError.message = data.message
            }

            if (data && data.errors) {
                normalizedError.errors = Object.keys(data.errors).reduce(
                    (acc, fieldName) => ({
                        ...acc,
                        [fieldName]: data.errors[fieldName][0],
                    }),
                    {}
                )
            }

            normalizedError.status = status
        }

        return Promise.reject(normalizedError)
    }
)

export const applyAuthInterceptors = ({
    getToken,
    setToken,
    clearToken,
    getTokenExpirationDate,
}: ApplyInterceptorsProps) => {
    axiosInstance.interceptors.request.use(
        async (config) => {
            config.headers['X-App-Token'] = REACT_APP_API_KEY

            const authToken = getToken()
            const authTokenExpirationDate = getTokenExpirationDate()

            if (authToken && authTokenExpirationDate) {
                const expirationTime =
                    new Date(authTokenExpirationDate).getTime() / 1000
                const currentTime = Date.now() / 1000

                config.headers.Authorization = `Bearer ${authToken}`

                if (
                    expirationTime - currentTime <
                    parseInt(REACT_APP_TOKEN_REFRESH_THRESHOLD_SECONDS, 10)
                ) {
                    if (!isRefreshing) {
                        if (!(expirationTime - currentTime > 0)) {
                            clearToken()

                            return config
                        }

                        isRefreshing = true

                        try {
                            const { data } = await refreshToken()
                            setToken(data.token, data.expires_at)
                            config.headers.Authorization = `Bearer ${data.token}`
                            return config
                        } catch (error) {
                            console.error('Refresh token error:', error)
                        } finally {
                            isRefreshing = false
                        }
                    }
                }
            }

            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response) {
                const { status } = error.response

                if (status === 401) {
                    clearToken()
                }
            }

            return Promise.reject(error)
        }
    )
}

export default axiosInstance
