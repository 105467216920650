import React from 'react'

import { useRemindPassword } from '../api'
import ForgotPassword from '../components/ForgotPassword'

import type { RemindPasswordForm, FormSubmitFn } from '../types'

export default function ForgotPasswordContainer() {
    const { mutate: requestResetPassword, isSuccess } = useRemindPassword()

    const handleSubmit: FormSubmitFn<RemindPasswordForm> = (
        values,
        formikHelpers
    ) => {
        requestResetPassword(values, {
            onSuccess: () => {
                formikHelpers.setSubmitting(false)
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
            },
        })
    }

    return <ForgotPassword handleSubmit={handleSubmit} isSuccess={isSuccess} />
}
