import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

import { Spinner } from '.'
import { getCxFromStyles } from '../../helpers'

import styles from './Button.module.scss'

type BaseProps = {
    children: React.ReactNode
    className?: string
}

type ButtonAsButton = BaseProps &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
        as?: 'button'
    }

type ButtonAsLink = BaseProps &
    Omit<LinkProps, keyof BaseProps> & {
        as: 'link'
        state?: any
    }

export interface ButtonCustomProps {
    className?: string
    styles?: Record<string, string>
    icon?: React.ReactElement
    iconLeft?: React.ReactElement
    iconRight?: React.ReactElement
    variant?:
        | 'default'
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'quaternary'
        | 'transparent'
        | 'danger'
        | 'link'
    size?: 'sm' | 'md'
    disabled?: boolean
    hovered?: boolean
    focused?: boolean
    loading?: boolean
    outline?: boolean
}

export type ButtonProps = (ButtonAsButton | ButtonAsLink) & ButtonCustomProps

const Button = React.forwardRef<
    HTMLButtonElement | HTMLAnchorElement | null,
    ButtonProps
>(
    (
        {
            children,
            styles: customStyles,
            icon,
            iconLeft,
            iconRight,
            variant = 'default',
            disabled = false,
            hovered = false,
            focused = false,
            loading = false,
            size = 'md',
            outline = true,
            ...props
        },
        ref
    ) => {
        const cx = getCxFromStyles(styles, customStyles)

        const classNameCx = cx(
            'root',
            variant,
            size,
            {
                outline,
                disabled,
                hovered,
                focused,
                loading,
            },
            props.className ? props.className : {}
        )

        if (props.as === 'link') {
            const { as, className, ...rest } = props
            return (
                <Link
                    ref={ref as React.ForwardedRef<HTMLAnchorElement>}
                    className={classNameCx}
                    {...rest}
                >
                    <div className={cx('children')}>
                        {iconLeft && (
                            <span className={cx('children--icon-left')}>
                                {iconLeft}
                            </span>
                        )}
                        {icon && (
                            <span className={cx('children--icon-left')}>
                                {icon}
                            </span>
                        )}
                        {children}
                        {iconRight && (
                            <span className={cx('children--icon-right')}>
                                {iconRight}
                            </span>
                        )}
                    </div>
                </Link>
            )
        }

        const { as, className, ...rest } = props
        return (
            <button
                ref={ref as React.ForwardedRef<HTMLButtonElement>}
                className={classNameCx}
                disabled={disabled || loading}
                {...rest}
            >
                <div className={cx('children')}>
                    {iconLeft && (
                        <span className={cx('children--icon-left')}>
                            {iconLeft}
                        </span>
                    )}
                    {icon && (
                        <span className={cx('children--icon-left')}>
                            {icon}
                        </span>
                    )}
                    {children}
                    {iconRight && (
                        <span className={cx('children--icon-right')}>
                            {iconRight}
                        </span>
                    )}
                </div>
                {loading && (
                    <div className={cx('spinner')}>
                        <Spinner size="sm" variant={variant} />
                    </div>
                )}
            </button>
        )
    }
)

export default Button
