import React, { useState } from 'react'

import { useDownloadTemplate, useFlashMessage } from '../hooks'
import { useAssignmentInfo, useAssignmentUploadFile } from '../api'
import { Loader } from '../components/ui'
import PageLayout from '../components/PageLayout'
import Assignment from '../components/Assignment'

import type { FormSubmitFn, FileUploadForm } from '../types'
import { useQueryClient } from 'react-query'

export default function AssignmentContainer() {
    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const downloadFile = useDownloadTemplate()
    const assignmentInfoQuery = useAssignmentInfo()
    const assignmentMutation = useAssignmentUploadFile()

    const [fileDownloading, setFileDownloading] = useState(false)

    const handleSubmit: FormSubmitFn<FileUploadForm> = (
        values,
        formikHelpers
    ) => {
        if (!values.file) {
            return
        }

        assignmentMutation.mutate(
            { file: values.file },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries('assignments-info')
                    formikHelpers.setSubmitting(false)
                    formikHelpers.resetForm()
                    flashMessage({
                        type: 'success',
                        content: 'Zadadnia zostały wgrane poprawnie.',
                    })
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                    flashMessage({
                        type: 'danger',
                        content: 'Nie udało się wgrać zadań z powodu błędów.',
                    })
                },
            }
        )
    }

    const handleTemplateDownload = async (filename?: string) => {
        setFileDownloading(true)

        try {
            await downloadFile({
                fileName: filename || 'Zadania_Oleofarm-szablon.xls',
            })
            setFileDownloading(false)
        } catch {
            setFileDownloading(false)
        }
    }

    if (assignmentInfoQuery.isLoading) {
        return <Loader />
    }

    if (assignmentInfoQuery.isError) {
        return (
            <div className="text-red-600">
                {assignmentInfoQuery.error.message}
            </div>
        )
    }

    if (!assignmentInfoQuery.isSuccess) {
        return null
    }

    return (
        <PageLayout title="Zadania Oleofram">
            <Assignment
                data={assignmentInfoQuery.data.data}
                onSubmit={handleSubmit}
                fileDownloading={fileDownloading}
                onFileDownload={handleTemplateDownload}
            />
        </PageLayout>
    )
}
